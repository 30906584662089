import type { VFC } from 'react';
import { Fragment } from 'react';

import DesktopTopNavigation from '@/components/Navigation/DesktopTopNavigation';

import { mainCx } from './styles';
import type { DeviceLayoutProps } from '../types';

/**
 * @function Desktop
 */
const Desktop: VFC<DeviceLayoutProps> = props => {
  const { children, withDesktopTopNavigation = false, withDesktopTopNavigationTranslucentBehaviour = false } = props;
  return (
    <Fragment>
      {withDesktopTopNavigation ? (
        <DesktopTopNavigation translucentBehaviour={withDesktopTopNavigationTranslucentBehaviour} />
      ) : null}
      <main className={mainCx} data-device="desktop">
        {children}
      </main>
    </Fragment>
  );
};

export default Desktop;
