import dynamic from 'next/dynamic';
import type { DesktopTopNavigationProps } from './types';

const LazyDesktopTopNavigation = dynamic<DesktopTopNavigationProps>(
  () => import(/* webpackChunkName: "desktop-top-navigation" */ './DesktopTopNavigation'),
  {
    ssr: true,
  },
);

export default LazyDesktopTopNavigation;
